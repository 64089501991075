section.attendee {
  position: relative;
  /*padding: 250px 0;*/
  //background-image: url("/img/attendees.jpg");
  //background-position: center;
  //-webkit-background-size: cover;
  //-moz-background-size: cover;
  //-o-background-size: cover;
  //background-size: cover;
  z-index: 1;

  p, li, h2, h5, h6 {

    text-shadow: 1px 1px 4px black;

  }

  p, li, h2, h6 {
    color: $white;
    text-shadow: 1px 1px 4px black;

  }

  h5{
    color: theme-color("warning");
  }

  p, li {
    font-size: 1.3em;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@media (min-width: 768px) {
  section.attendee .attendee-content h2 {
    font-size: 80px;
  }
}