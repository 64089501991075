section.hotel {
  background: #53B8FF;
  background-image: url("/img/hotel-mist-sushi.jpg");
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

section.hotel .section-heading {
  margin-bottom: 100px;
}