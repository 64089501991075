
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;

  background-color: black;
  z-index: -100;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    background-image: url('/img/bg-576.png');
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    background-image: url('/img/bg-768.png');
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    background-image: url('/img/bg-992.png');
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    background-image: url('/img/bg-1200.png');
  }


}


canvas {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -50;
  //vertical-align: bottom;
}

body {
position: relative;
  font-family: 'Open Sans', sans-serif;
}

a {
  color: $link-color;
  -webkit-transition: all .35s;
  -moz-transition: all .35s;
  transition: all .35s;
}

a:hover, a:focus {
  color: $link-hover-color;
}

hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

hr.light {
  border-color: white;
}

p {
  //font-size: 18px;
}

section {
  padding: 1em 0;

  .section-heading {
    letter-spacing: 1px;
    margin-bottom: 1.1em;
  }
}

div.card.sold {
  //background: url("/img/sold.png") no-repeat top;
  //background-size: contain;
  ////min-height: 460px;
}

iframe[name="google_conversion_frame"] {
  display: none;
}


.card-columns {
  @include media-breakpoint-only(sm) {
    column-count: 1;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }
}