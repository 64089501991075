section#registration {
  //background: rgb(0, 0, 0);
  position: relative;
  //background-image: url("/img/event-cover.jpg");
  //background-position: center;
  //-webkit-background-size: cover;
  //-moz-background-size: cover;
  //-o-background-size: cover;
  //background-size: cover;
  z-index: 1;
}

#nav-registration{
  @include media-breakpoint-only(sm) {
    border: none !important;
  }
  @include media-breakpoint-only(md) {
    border: none !important;
  }
}